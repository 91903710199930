.tilter {
  display: block;
  position: relative;
  flex: none;
  perspective: 1000px;
}

.tilter__figure,
.tilter__image {
  margin: 0;
  width: 100%;
  height: 100%;
  display: block;
}

@media (min-width: 60em) {

  .tilter * {
    pointer-events: none;
  }

  .tilter__box {
    // backface-visibility: visible;
    perspective: 1000px;
  }

  .tilter__figure > * {
    transform: translateZ(0px); /* Force correct stacking order */
  }

  .tilter__figure {
    position: relative;
  }

  .tilter__figure::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: -24px 48px 32px rgba(35, 32, 39, 0.25);
    opacity: 0;
  }

  .tilter__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .tilter__deco--hover-overlay {
    transition:
            background-image 300ms ease-out,
            opacity 300ms ease-out;
    background-color: rgba(17, 14, 46, 0.4);
    opacity: 0;
  }

  .tilter__deco--overlay {
    transition:
            // background-image 300ms ease-out,
            opacity 300ms ease-out;
    background-image: linear-gradient(45deg, rgba(17, 14, 46, 0.7), rgba(17, 14, 46, 0.7), rgba(17, 14, 46, 0.7));
    opacity: 0;
  }

  .tilter__items:hover > .tilter:hover .tilter__deco--overlay,
  .tilter__items:focus > .tilter:focus .tilter__deco--overlay {
    opacity: 1;
  }

  .tilter__deco--shine div {
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    transition:
            // background-image 300ms ease,
            opacity 300ms ease;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 100%);
    opacity: 0;
  }

  .tilter__items:hover > .tilter:hover .tilter__deco--shine div,
  .tilter__items:focus > .tilter:focus .tilter__deco--shine div {
    opacity: 1;
  }

  .tilter__title { margin: 0; }

  .tilter__items .tilter .tilter__box {
    transition:
            all 400ms ease-out
    // filter 600ms ease-out;
  }

  .tilter__items:hover > .tilter .tilter__box,
  .tilter__items:focus > .tilter .tilter__box {
    opacity: 0.5;
    // filter:
    //   blur(2px)
    //   opacity(0.95);
  }
  .tilter__items:hover .tilter .tilter__deco--hover-overlay,
  .tilter__items:focus .tilter .tilter__deco--hover-overlay { opacity: 1; }

  .tilter__items:hover > .tilter:hover .tilter__box,
  .tilter__items:focus > .tilter:focus .tilter__box {
    transform: scale(1.075);
    opacity: 1;
    // filter: blur(0);
  }

  .tilter__items:hover > .tilter:hover .tilter__figure::before,
  .tilter__items:focus > .tilter:focus .tilter__figure::before { opacity: 1; }

}
