footer {

  @media screen and (min-width: 60em) {
    width: calc(100% - 8rem);
    margin-left: 8rem;
  }
  @media screen and (min-width: 80em) {
    width: calc(100% - 16rem);
  }

}
