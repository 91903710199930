@mixin font-face($family, $name, $type, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('/src/fonts/#{$family}_#{$name}.#{$type}');
    font-weight: $weight;
    font-display: swap;
  }
}

@mixin down($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin up($breakpoint) {
  @media (min-width: calc(map-get($breakpoints, $breakpoint) + 1px)) {
    @content;
  }
}
