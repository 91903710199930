$susy: (
        columns: 12,
        gutters: 0.25em/1em,
        debug: (
                image: hide,
                color: rgba(#66f, .25),
                output: background
        )
);

.wrap {
  position: relative;
  @include container(90em);
}

.col-1 {
  width: span(1 of 12)
}

.col-2 {
  width: span(2 of 12)
}

.col-3 {
  width: span(3 of 12)
}

.col-4 {
  width: span(4 of 12)
}

.col-5 {
  width: span(5 of 12)
}

.col-6 {
  width: span(6 of 12)
}

.col-7 {
  width: span(7 of 12)
}

.col-8 {
  width: span(8 of 12)
}

.col-9 {
  width: span(9 of 12)
}

.col-10 {
  width: span(10 of 12)
}

.col-11 {
  width: span(11 of 12)
}

.col-12 {
  width: span(12 of 12)
}

@media screen and (min-width: 30em) {
  .col-1-ns {
    width: span(1 of 12)
  }
  .col-2-ns {
    width: span(2 of 12)
  }
  .col-3-ns {
    width: span(3 of 12)
  }
  .col-4-ns {
    width: span(4 of 12)
  }
  .col-5-ns {
    width: span(5 of 12)
  }
  .col-6-ns {
    width: span(6 of 12)
  }
  .col-7-ns {
    width: span(7 of 12)
  }
  .col-8-ns {
    width: span(8 of 12)
  }
  .col-9-ns {
    width: span(9 of 12)
  }
  .col-10-ns {
    width: span(10 of 12)
  }
  .col-11-ns {
    width: span(11 of 12)
  }
  .col-12-ns {
    width: span(12 of 12)
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .col-1-m {
    width: span(1 of 12)
  }
  .col-2-m {
    width: span(2 of 12)
  }
  .col-3-m {
    width: span(3 of 12)
  }
  .col-4-m {
    width: span(4 of 12)
  }
  .col-5-m {
    width: span(5 of 12)
  }
  .col-6-m {
    width: span(6 of 12)
  }
  .col-7-m {
    width: span(7 of 12)
  }
  .col-8-m {
    width: span(8 of 12)
  }
  .col-9-m {
    width: span(9 of 12)
  }
  .col-10-m {
    width: span(10 of 12)
  }
  .col-11-m {
    width: span(11 of 12)
  }
  .col-12-m {
    width: span(12 of 12)
  }
}

@media screen and (min-width: 60em) {
  .col-1-l {
    width: span(1 of 12)
  }
  .col-2-l {
    width: span(2 of 12)
  }
  .col-3-l {
    width: span(3 of 12)
  }
  .col-4-l {
    width: span(4 of 12)
  }
  .col-5-l {
    width: span(5 of 12)
  }
  .col-6-l {
    width: span(6 of 12)
  }
  .col-7-l {
    width: span(7 of 12)
  }
  .col-8-l {
    width: span(8 of 12)
  }
  .col-9-l {
    width: span(9 of 12)
  }
  .col-10-l {
    width: span(10 of 12)
  }
  .col-11-l {
    width: span(11 of 12)
  }
  .col-12-l {
    width: span(12 of 12)
  }
}

@media screen and (min-width: 80em) {
  .col-1-xl {
    width: span(1 of 12)
  }
  .col-2-xl {
    width: span(2 of 12)
  }
  .col-3-xl {
    width: span(3 of 12)
  }
  .col-4-xl {
    width: span(4 of 12)
  }
  .col-5-xl {
    width: span(5 of 12)
  }
  .col-6-xl {
    width: span(6 of 12)
  }
  .col-7-xl {
    width: span(7 of 12)
  }
  .col-8-xl {
    width: span(8 of 12)
  }
  .col-9-xl {
    width: span(9 of 12)
  }
  .col-10-xl {
    width: span(10 of 12)
  }
  .col-11-xl {
    width: span(11 of 12)
  }
  .col-12-xl {
    width: span(12 of 12)
  }
}
