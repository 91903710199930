.model__intro {

  @media screen and (min-width: 60em) {

    padding-left: 8.25em;
  }
  @media screen and (min-width: 80em) {
    padding-right: 8em;
  }
}

.aside__sidebar {

  @media screen and (min-width: 60em) {
    width: 10em;
  }
}

.aside__sidebar .aside__model-name {
  transform: translateX(-0.5rem);
  transition: all 200ms ease-out;
}
.aside__sidebar.show__model-name .aside__model-name {
  transform: translateX(0);
  opacity: 1;
}


@media screen and (min-width: 60em) {

  .model .media__items {
    width: calc(100% - 10em);
  }

  .model .media__items figure.photo a,
  .model .media__items figure.polaroid a {
    transition: all 300ms ease-out;
    transform: translate3d(-0.5rem, -0.5rem, 0);
  }

  .model .media__items figure.photo:hover a,
  .model .media__items figure.polaroid:hover a,
  .model .media__items figure.photo:focus a,
  .model .media__items figure.polaroid:focus a {
    opacity: 1;
    // transform: translate3d(-1rem, -1rem, 0);
  }

  .model .media__items figure.photo:hover a:hover,
  .model .media__items figure.polaroid:hover a:hover,
  .model .media__items figure.photo:focus a:focus,
  .model .media__items figure.polaroid:focus a:focus {
    background-color: $darkBlue;
  }

  .model .media__items figure.photo:hover a:hover span,
  .model .media__items figure.polaroid:hover a:hover span,
  .model .media__items figure.photo:focus a:focus span,
  .model .media__items figure.polaroid:focus a:focus span { animation: bounceUp 600ms ease-out; }
}

@media screen and (min-width: 80em) {

  .model .media__items {
    width: calc(100% - 18em);
    margin-right: 8em;
  }
}

@keyframes bounceUp {
  0% { transform: translateY(0); }
  25% { transform: translateY(-0.15rem); }
  50% { transform: translateY(0); }
  75% { transform: translateY(-0.15rem); }
  100% { transform: translateY(0); }
}
