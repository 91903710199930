$fonts: (
        'Avenir' : (
                300 : '3',
                500 : '5',
                700 : '7',
                900 : '9'
        )
);
$fonts-path: '/src/fonts';
$font-file-types: ( 'eot', 'woff', 'woff2' );
$Avenir: map-get($fonts, Avenir);
$width: map-keys($Avenir);



@each $type in $font-file-types {
  @include font-face(Avenir, '3', $type, 300);
  @include font-face(Avenir, '5', $type, 500);
  @include font-face(Avenir, '7', $type, 700);
  @include font-face(Avenir, '9', $type, 900);
}

