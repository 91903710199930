.selection .models { min-height: 55vh; }

.required {

  label:after {
    content: ' *';
    color: $red;
  }
}

.selection-overlay input[type='text'],
.selection-overlay input[type='email'],
.selection-overlay textarea {
  outline: none;
  border: 0;
  border-bottom: 1px solid $darkGrey;
  border-color: rgba( 143, 141, 141, .2);
}

.selection-overlay button[type='submit'] {
  background-color: rgba(16, 14, 45, .7);
  transition: all 300ms ease-out;
}
.selection-overlay button[type='submit']:hover,
.selection-overlay button[type='submit']:active,
.selection-overlay button[type='submit']:focus { background-color: rgba(16, 14, 45, 1); }

.selection-overlay input[type="text"],
.selection-overlay input[type="email"],
.selection-overlay input[type="password"] {

  &:valid {
    background: url('/src/img/valid.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 95% 0.25rem;
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    background: url('/src/img/invalid.svg');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: 95% 0.25rem;
  }

  // When that invalid input becomes in focus (and also still isn't empty)
  &:invalid:focus:not(:placeholder-shown) {

  }
}
