.plyr__play-large {
  z-index: 2;
  cursor: pointer;
}

.plyr--playing {
  z-index: 3;
}

.plyr--video .plyr__controls {
  background: transparent;
}

.plyr__play-large {
  border: 2px solid #F9F2EB;
  background: transparent;
  padding: 1rem;
  box-shadow: none;

  padding: 1.25rem;
}

.plyr__progress--played,
.plyr__volume--display { color: $darkBlue; }