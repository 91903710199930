.search__loupe {
  transition: transform 300ms ease-out;
}
.search__loupe:hover {
  transform: translateX(-0.25em);
}

.search__close {
  transition: transform 300ms ease-out;
  right: 0.5rem;
}
.search__close:hover {
  transform: translateX(0.25em);
}
.search__overlay .search__wrapper {
  width: 100%;

  @media (min-width: 60em) {
    width: 30em;
  }
  @media (min-width: 70em) {
    width: 35em;
  }
  @media (min-width: 80em) {
    width: 40em;
  }
  @media (min-width: 90em) {
    width: 45em;
  }
}

.search__wrapper .search__box {
  width: calc(100% - 4rem);
  position: absolute;
  top: -0.25rem;
  right: 1rem;
}

.search__wrapper .search__box input {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  border: none;
  outline: none;
  color: $darkBlue;
  border-bottom: 1px solid $darkBlue;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.78;
  letter-spacing: 1px;
  background-color: transparent;

  @media (min-width: 60em) {
    font-size: 1rem;
    line-height: 1.56;
  }
}

.search__wrapper .search__box input::placeholder {

  @media (min-width: 60em) {
    color: $darkGrey;
    opacity: 0.5;
    text-align: left;
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: 1px;
  }
}

.search__wrapper .search__box .autocomplete-list { margin-top: 2rem; }

.search__wrapper .search__box .autocomplete-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  background-color: transparent;
  list-style: none;

  @media (min-width: 60em) {
    text-align: right;
  }
}

.search__wrapper .search__box .autocomplete-list ul li {
  width: 100%;
  animation: slideIn 400ms ease-out;
}

.search__wrapper .search__box .autocomplete-list ul li a {
  color: $darkBlue;
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 4rem;
  letter-spacing: 0.0625rem;
  text-decoration: none;
  transition: color .15s ease-in;

  @media (min-width: 60em) {
    font-size: 3rem;
    line-height: 5rem;
    letter-spacing: 0.03125rem;
  }
}
.search__wrapper .search__box .autocomplete-list ul li a:hover,
.search__wrapper .search__box .autocomplete-list ul li a:focus,
.search__wrapper .search__box .autocomplete-list ul li a:active { color: $darkGrey; }

.search__wrapper .search__box .autocomplete-list ul li a b {
  font-weight: 900;
}

@keyframes slideIn {
  0% { opacity: 0; transform: translateX(2rem); }
  100% { opacity: 1; transform: translateX(0); }
}
