/* === YNA === */
$breakpoints: (
        'sm': 560px,
        'md': 768px,
        'lg': 991px,
        'xl': 1128px,
);

$z-index: (
        'page-header': 10,
        'page-sticker': 5,
        'over-sticker': 6,
        'nav-menu': 15,
        'modal': 20,
        'cookies': 30,
        'transition-overlay': 40
);

$ff-primary: 'Arial', sans-serif;
$ff-secondary: 'Verdana', sans-serif;

/* === De-Boekers === */
$accent: #5bd5be;

$darkBlue: #100E2D;
$darkerBlue: #110E2E;
$darkGrey: #8F8D8D;
$red: red;