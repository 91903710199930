.hamburger svg .bar {
  transform-origin: left;
  will-change: transform;
}

.hamburger svg .bar:nth-of-type(1) { transition: transform 300ms ease-out; }
.hamburger svg .bar:nth-of-type(2) { transition: transform 300ms ease-out 50ms; }
.hamburger svg .bar:nth-of-type(3) { transition: transform 300ms ease-out 150ms; }

.hamburger:hover .bar:nth-of-type(1) { transform: translateX(.25rem); }

.hamburger:hover .bar:nth-of-type(2) { transform: translateX(.25rem); }

.hamburger:hover .bar:nth-of-type(3) { transform: translateX(.65rem); }

.hamburger.active .bar:nth-of-type(1) { transform: translateY(0.45rem); }

.hamburger.active .bar:nth-of-type(2) { transform: translateX(0); }

.hamburger.active .bar:nth-of-type(3) { transform: translateY(-0.45rem); }

@media screen and (min-width:60em) {

  .hamburger:hover .bar:nth-of-type(1) { transform: translateX(0); }

  .hamburger:hover .bar:nth-of-type(2) { transform: translateX(0); }

  .hamburger:hover .bar:nth-of-type(3) { transform: translateX(.55rem); }

  .hamburger.active .bar:nth-of-type(1) { transform: translateY(0.5rem); }

  .hamburger.active .bar:nth-of-type(2) { transform: translateX(0); }

  .hamburger.active .bar:nth-of-type(3) { transform: translateY(-0.5rem); }
}

.hamburger__close {

  svg .bar {
    transform-origin: left;
    will-change: transform;
  }

  .bar:nth-of-type(1) {
    animation: closeHamburgerBarOne 300ms ease-out;
  }
  .bar:nth-of-type(2) {
    animation: closeHamburgerBarTwo 300ms ease-out;
  }
}

.selection__counter {
  position: absolute;
  border-radius: 50%;
  width: 1.65rem;
  height: 1.65rem;
  padding: .5rem;
  background-color: rgba(17, 14, 46, 0.7);
  color: #fff;
  font-weight: 900;
  line-height: 1;
  font-size: 0.75rem;
  text-align: center;
  margin-left: 3rem;
  margin-top: -0.2rem;
  text-decoration: none;
  transition: all 300ms ease-out;
}

.selection__counter:hover,
.selection__counter:active,
.selection__counter:focus {
  background-color: rgba(17, 14, 46, 1);
}

@keyframes closeHamburgerBarOne {
  0% { transform: translateY(0.35rem) rotate(-45deg); }
  25% { transform: translateY(0.35rem) rotate(-45deg); }
  100% { transform: translateY(0) rotate(0); }
}

@keyframes closeHamburgerBarTwo {
  0% { transform: translateY(-0.35rem) rotate(45deg); }
  25% { transform: translateY(-0.35rem) rotate(45deg); }
  100% { transform: translateY(0) rotate(0); }
}
